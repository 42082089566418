//==============================================================================
// Global Constants
//
// Consolidated list of constants reused across different modules and extensions
//==============================================================================

//----------------------------------------------------------
// Attribute names list
//----------------------------------------------------------
export const attrNames = {
    productType: 'Item Type',
    lineAttributes: 'LineAttributes',
    blacklistedShippingAreas: 'Embargoed shipping locations',
    kitConfigurator: 'KitConfigurator',
    kitContents: 'eCommBurstoutKitContents',
    kitFormats: 'eCommBurstoutKitFormats',
    kitElectives: 'eCommBurstoutKitElectives',
    kitSubstitutions: 'eCommBurstoutKitSubstitutions',
    kitLineSubstitutions: 'eCommBurstoutKitLineDetails',
    kitLineParentAssociation: 'eCommBurstoutKitOrderLineParentAssociation',
    kitLineChildAssociation: 'eCommBurstoutKitOrderLineChildAssociation',
    instructors: 'eCommInstructors',
    consultantCartAttribute: 'eCommConsultantId',
    isInstallmentEligible: 'eCommItemIsPPEligible',
    cashForCollege: 'eCommCash4College',
    dropShip: 'eCommDropShip',
    scoringType: 'TestScoring',
    studentId: 'eCommPTEStudentId',
    schoolIdPP: 'eCommPPSchoolId',
    testCoordinator: 'PTETestCoordinator',
    testEnrollment: 'PTESchoolEnrollment',
    testType: 'Test Type',
    testFormat: 'Test Format',
    testDate: 'eCommPTETestDate',
    testDateId: 'eCommPTETestDateId',
    testAdmin: 'eCommPTEAdminCustAccount',
    testProctorEmail: 'PTEProctorEmail',
    testProctorName: 'PTEProctorName',
    testProctorPhone: 'PTEProctorPhone',
    testProctorTimeZone: 'PTEProctorTimeZone',
    userAgreement: 'eCommUserAgreement',
    umbrellaGroup: 'UmbrellaGroupID',
    gradeLvl: 'Grade level',
    isNotTest: 'Other',
    onlineTest: 'Online test',
    purchaseItemType: 'TestBklt',
    notBuyable: 'Not Buyable',
    billingCountry: 'eCommBillingCountryCode',
    billingZipCode: 'eCommBillingZipPostalCode',
    paymentScheduleName: 'PaymentScheduleName',
    isDBOrder: 'IsDBOrder',
    troveAdminFirstName: "TroveAdminFirstName",
    troveAdminLastName: "TroveAdminLastName",
    troveAdminEmail: "TroveAdminEmail",
    troveAdminTelephone: "TroveAdminTelephone",
    troveAnnualLicense: "TroveAnnualLicense",
    troveProduct: "Trove",
    troveParticipant: "TroveParticipant",
    poNumber: "CSPONumber",
    publicSearchable: "Publicly Searchable",
    eCommUserAgreement: "eCommUserAgreement"
};

//----------------------------------------------------------
// Global label resources
//----------------------------------------------------------
export const labelResources = {
    sku: 'SKU'
};
